<template>
	<div class="Employee_Information_detial" v-loading="loading">
		<div class="top_title">
			<div class="top_name">{{form.name}}</div>
			<div class="top_info_flex">
				<div class="top_info_text">性别：{{form.sex}}</div>
				<div class="top_info_text">部门：{{form.depart_title}}</div>
				<div class="top_info_text">岗位：{{form.level6}}</div>
				<div class="top_info_text">手机号：{{form.mobile}}</div>
				<div class="top_info_text">员工号：{{form.gonghao}}</div>
				<div class="top_info_text">证件类型及证件号：{{form.id_type}} {{form.id_card}}</div>
			</div>
		</div>
		<div class="info_box">
			<div class="info_check">
				<div class="checks">
					<div class="check_item" v-for="(item,index) in checks_list" :key="index"
						:class="{check_item_ac:$route.path==item.path}" @click="handle_ac(item,index)">
						<div class="check_title">{{ item.title }}</div>
						<div class="check_border"></div>
					</div>
				</div>
				<div class="borders"></div>
			</div>
			<div class="routers">
				<!-- 员工基础信息 -->
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		components: {},
		data() {
			return {
				loading: false,
				user_id: '',
				form: {},
				checks_ac: 0,
				checks_list: [{
						title: '员工基础信息',
						path: '/Employee_Information_detial/employee_info',
					},
					{
						title: '通讯录信息',
						path: '/Employee_Information_detial/tongxunlu_info',
					},
					{
						title: '合同记录',
						path: '/Employee_Information_detial/employee_ht',
					},
					{
						title: '调动记录',
						path: '/Employee_Information_detial/employee_dd'
					},
					{
						title: '福利记录',
						path: '/Employee_Information_detial/employee_fl'

					},
					{
						title: '劳保记录',
						path: '/Employee_Information_detial/employee_lb'

					},
					{
						title: '工资配置',
						path: '/Employee_Information_detial/employee_gz'

					},
					{
						title: '工资记录',
						path: '/Employee_Information_detial/employee_gzjl'

					},
					{
						title: '考勤记录',
						path: '/Employee_Information_detial/employee_kq'

					},
					{
						title: '离职记录',
						path: '/Employee_Information_detial/employee_lz'

					},
					{
						title: '社保配置',
						path: '/Employee_Information_detial/employee_sb'

					}
				],

			};
		},
		methods: {
			handle_ac(item, i) {
				this.$router.push({
					path: item.path,
					query: {
						id: this.user_id,
						name: this.form.name
					}
				})
				this.checks_ac = i
			},
			get_user() {
				this.loading = true
				this.$api("getUser", {
					id: this.user_id,
				}, "get").then((res) => {
					console.log('详情', res)
					this.loading = false
					if (res.code == 200) {
						this.form = res.data

					} else {
						alertErr(res.msg)
					}
				});
			},



		},
		computed: {},

		watch: {

		},


		created() {
			this.user_id = this.$route.query.id
			this.get_user()

		},


	};
</script>

<style scoped lang="less">
	.Employee_Information_detial {
		width: 1640px;

		// height: 1743px;
		// background: #FFFFFF;
		.top_title {
			padding: 28px 53px 23px 53px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #333333;
			border: 1px solid #E6E6E6;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;

			.top_name {
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 24px;
				color: #2373C8;
			}

			.top_info_flex {
				display: flex;
				align-items: center;
				padding-top: 29px;
				flex-wrap: wrap;

				.top_info_text {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #4A4A4A;
					// width: 300px;
					margin-right: 100px;
					margin-bottom: 10px;
				}
			}
		}

		.info_box {
			margin-top: 19px;
			padding: 0 37px 0 21px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #E6E6E6;

			.info_check {
				height: 63px;

				.checks {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 60px;
					padding: 0 16px;

					.check_item {
						height: 60px;
						// margin-right: 85px;
						cursor: pointer;

						// display: flex;
						// flex-wrap: wrap;
						// align-content: space-between;
						.check_title {
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
							margin-top: 30px;
						}

						.check_border {
							margin-top: 10px;
							border: 2px solid #FFFFFF;

						}
					}

					.check_item:hover,
					.check_item_ac {
						.check_title {
							color: #2373C8;
						}

						.check_border {
							border: 2px solid #2373C8;
						}
					}


				}

				.borders {
					width: 1582px;
					height: 0px;
					border: 1px solid #707070;
					opacity: 0.2;
				}
			}
		}

	}
</style>